<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex flex-wrap justify-content-start">
        <div class="d-flex align-items-center">
          <TMessage content="Payment purchases" bold />
          <TSpinner :loading="creating" />
        </div>
        <div class="ml-auto">
          <TButtonClear
            variant="outline"
            color="primary"
            class="mr-1"
            @click="clearInput"
          />
          <TButton
            content="Create"
            icon="cis-plus"
            @click="create"
            :options="{ disabled: creating }"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="7">
            <CRow class="row-gap-3">
              <CCol md="6">
                <div
                  style="position: relative"
                  @click="showModalSelectPurchaseOrder = true"
                >
                  <div
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      z-index: 2;
                    "
                  />
                  <TInputText
                    label="Purchase"
                    :value="temp.purchase && temp.purchase.id"
                    placeholder="Select purchases"
                  />
                </div>
              </CCol>

              <CCol md="6">
                <TInputText
                  label="Supplier"
                  :value="temp.purchase && temp.purchase.supplier.name"
                  disabled
                />
              </CCol>
            </CRow>

            <CRow class="row-gap-3 mt-3">
              <CCol md="3">
                <TInputDateTime
                  label="Voucher date"
                  :value.sync="input.voucher_date"
                />
              </CCol>

              <CCol md="3">
                <!-- <SSelectCurrency
                  label="Currency"
                  :prepend="[]"
                  :value.sync="input.currency_id"
                /> -->
                <TInputText
                  label="Currency"
                  :value="lodash.symbolCurrency(input.currency_id)"
                  disabled
                />
              </CCol>

              <CCol md="6">
                <TInputMoney
                  label="Amount"
                  :value.sync="input.amount"
                  :currency="input.currency_id"
                />
              </CCol>

              <CCol md="12">
                <TInputTextarea
                  label="Description"
                  placeholder="Description"
                  :value.sync="input.description"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="5">
            <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <SModalSelectPurchaseOrder
      :show.sync="showModalSelectPurchaseOrder"
      @select="onChangePurchase"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      temp: {},
      input: {},
      receipts: [],
      showModalSelectPurchaseOrder: false,
    };
  },
  computed: {
    creating() {
      return this.$store.getters[
        "accounting.transactions_payment_purchase.creating"
      ];
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch(
          "accounting.transactions_payment_purchase.create",
          this.getDataCreate()
        )
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let params = {
        ...this.input,
        transactionable_type: "supplier",
        "receipts[0][receiptable_id]": this.temp?.purchase?.id,
        "receipts[0][receiptable_type]": "purchase",
      };

      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index + 1}][receiptable_type]`] = "file";
        params[`receipts[${index + 1}][file]`] = receipt.file;
      });

      return params;
    },
    clearInput() {
      this.temp = {};
      this.input = {};
      this.receipts = [];
    },
    onChangePurchase(purchase) {
      this.$set(this.temp, "purchase", purchase);
      this.$set(this.input, "transactionable_id", purchase.supplier_id);
      this.$set(this.input, "currency_id", purchase.currency_id);
      this.$set(this.input, "amount", purchase.outstanding);
    },
  },
};
</script>
