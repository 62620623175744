<template>
  <div class="w-100">
    <TTabs
      :tabs="tabs"
      :activeTab="$route.query.tab"
      @change-tab="changeTab"
      mountOnEnter
    >
      <template #payment-purchase>
        <PaymentPurchase />
      </template>
      <template #discounts-on-purchases>
        <DiscountsOnPurchases />
      </template>
    </TTabs>
  </div>
</template>

<script>
import PaymentPurchase from "./PaymentPurchase.vue";
import DiscountsOnPurchases from "./DiscountsOnPurchases.vue";

export default {
  components: {
    PaymentPurchase,
    DiscountsOnPurchases
  },
  data() {
    return {
      tabs: [
        {
          key: "payment-purchase",
          name: "Payment purchases",
          icon: "cisLibraryBooks",
        },
        {
          key: "discounts-on-purchases",
          name: "Payment refund",
          icon: "cisLibraryBooks",
        },
      ],
    };
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
